body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  overflow: hidden;
}

* {
  font-family: "Nova Square", sans-serif;
}
.scrollable-content {
  margin-right: -10px;
  max-height: calc(100vh - 64px);
  overflow-y: scroll;
}

#scrollable-content::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

#scrollable-content::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid #555555;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-menu-item {
  margin: 15px !important;
}

.ant-menu {
  margin-top: 15px !important;
  margin-right: 15px !important;
}

.ant-menu-item-selected {
  background-color: black;
}

.ant-menu-item-selected span {
  color: black;
}

.ant-menu-item-selected svg {
  fill: black;
}

.avatar {
  height: 160px;
  width: 160px;
  background-color: antiquewhite;
  margin: 20px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }

  50% {
    transform: scale(1.2);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0.8;
  }
}

.blinking-dot {
  width: 10px;
  height: 10px;
  background-color: rgb(66, 168, 66) !important;
  border-radius: 50% !important;
  animation: pulse 1s infinite;
}

.info-wrapper div {
  background-color: rgba(135, 218, 190, 0.2);
}

.social-link a {
  font-size: 20px;
}

.dropdown-item {
  width: 400px;
  margin: 10px 0px 10px 0px;
  border-radius: 50px !important;
}
.dropdown-menu a {
  padding: 15px;
  color: white;
}

@media screen and (max-width: 768px) {
  .sider-wrapper {
    display: none;
  }
}

@keyframes shine {
  0% {
    background-position-x: -500%;
  }

  100% {
    background-position-x: 500%;
  }
}

.connect-footer {
  border: 1px dashed white;
  padding: 20px;
  font-weight: 700;
  text-align: center;
  font-size: 40px;
  font-family: Hack, sans-serif;
  text-transform: uppercase;
  background: linear-gradient(90deg, #000, #fff, #000);
  letter-spacing: 5px;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-repeat: no-repeat;
  background-size: 80%;
  animation: shine 5s linear infinite;
  position: relative;
}
