.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Home */

.animate-charcter {
  font-weight: 900;
  font-size: 3.5em;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2.5s linear infinite;
  display: inline-block;
}

.title p {
  color: thistle;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.center {
  top: 0;
  left: 0;
}

.title {
  width: 100%;
  height: 50px;
  background-color: #f2d4ff;
}

.title .monitor-wrapper {
  background: #050321;
  width: 100%;
  height: 50px;
}

.title .monitor-wrapper .monitor {
  width: 100%;
  height: 50px;
  background-color: #344151;
  overflow: hidden;
  white-space: nowrap;
}

.title .monitor-wrapper .monitor p {
  font-family: "VT323", monospace;
  font-size: 35px;
  position: relative;
  display: inline-block;
  animation: move 20s infinite linear;
  color: #ebb55f;
}

@keyframes move {
  from {
    left: 800px;
  }

  to {
    left: -1600px;
  }
}

/* Home */

.location .icon {
  color: rgb(0, 255, 255);
}
.skill-wrapper {
  background-color: rgba(255, 255, 255, 0.4);
  border: 1px solid white;
  padding: 10px;
  margin: 15px;
}

.skill-wrapper a {
  margin-right: 10px;
}

/* Project Card */
.card-text {
  max-height: 3em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* About */

.cover-wrapper p {
  color: white;
}

/* Projects */
.project-img {
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}
.project-img:hover {
  transform: scale(1.05);
  border: none;
}
@media screen and (min-width: 769px) {
  section > div {
    width: 500px !important;
    height: 450px !important;
  }
}
section > div {
  width: 280px;
  height: 350px;
  border: 2px dotted rgb(204, 79, 198);
  position: relative;
}

.progress1 {
  position: sticky;
  top: 0;
  width: 80px;
  height: 80px;

  margin: 0;
  padding: 0;
}

.progress1 svg {
  transform: translateX(-100px) rotate(-90deg);
}

.bg {
  stroke: rgb(53, 223, 235);
}

figure circle {
  stroke-dashoffset: 0;
  stroke-width: 5%;
}

figure {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
}

.progress1 .indicator {
  stroke: red;
}

:not(svg) {
  transform-origin: 0px 0px;
}

/* SKills */

.rounded-wrapper {
  width: 50vh;
  height: 50vh;
  margin: 0 0 0 0;
  position: relative;
  border-radius: 50%;
}

.center-image img {
  border-radius: 50%;
}

.skills-wrapper svg {
  color: rgb(87, 178, 238);
  width: 50px;
  height: 50px;
  justify-self: center;
  transform-origin: center center;
  transform: rotate(0deg);
  transition: transform 0.5s ease-in-out;
}
.skills-wrapper svg:hover {
  transform: rotate(360deg);
}
@keyframes rotateImages {
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotateImages {
  to {
    transform: rotate(360deg);
  }
}

.light-beam {
  border-bottom-right-radius: 100%;
}
/* SKills */

/* Contact */
.contact-infomation a:hover {
  color: rgb(63, 253, 190) !important;
}

.contact-infomation a:hover svg{
  transform: scale(1.3);
}

.contact-infomation a svg {
  transition: transform 0.3s ease;
}
